import { AbilityBuilder, AbilityClass, PureAbility } from '@casl/ability'

type TActions = 'create' | 'read' | 'update' | 'delete'
type TAvailableActions = 'manage' | TActions

type TReportCommentsPublic = 'ReportCommentsPublic'
type TReportCommentsPrivate = 'ReportCommentPrivate'
type TAlertsCaptures = 'AlertsCaptures'
type TAlertsReports = 'AlertsReports'
type TCpaturesCommentsPublic = 'CaptureCommentsPublic'
type TCommunicationsNotes = 'CommunicationsNotes'
type TCommunicationsParents = 'CommunicationsParents'
type TCommunicationsStaff = 'CommunicationsStaff'
type TFreshdeskWidget = 'FreshdeskWidget'

type TAvailableSubjects =
  | 'all'
  | TReportCommentsPublic
  | TReportCommentsPrivate
  | TAlertsCaptures
  | TAlertsReports
  | TCpaturesCommentsPublic
  | TCommunicationsNotes
  | TCommunicationsParents
  | TCommunicationsStaff
  | TFreshdeskWidget

export type TAppTheme = 'moments' | 'infocare'

export type TAppAbility = PureAbility<[TAvailableActions, TAvailableSubjects]>

export const AppAbility = PureAbility as AbilityClass<TAppAbility>

export const defineRulesFor = (theme: TAppTheme) => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility)

  switch (theme) {
    case 'moments':
      can('manage', 'all')
      cannot('manage', 'FreshdeskWidget')
      break

    case 'infocare':
      can('manage', 'ReportCommentPrivate')
      can('manage', 'AlertsReports')
      cannot('manage', 'AlertsCaptures')
      cannot('manage', 'CaptureCommentsPublic')
      cannot('manage', 'CommunicationsNotes')
      cannot('manage', 'CommunicationsParents')
      cannot('manage', 'CommunicationsStaff')
      cannot('manage', 'ReportCommentsPublic')
      cannot('manage', 'FreshdeskWidget')
      break

    default:
      cannot('manage', 'all')
      break
  }

  return rules
}

export const buildThemeAbilityFor = (theme: TAppTheme) => {
  return new AppAbility(defineRulesFor(theme))
}
