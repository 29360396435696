export const white = '#FFFFFF'

export const infocarePrimary = {
  25: '#F3FCFE',
  50: '#E6F9FD',
  100: '#D4F5FC',
  200: '#C2F1FB',
  300: '#A3EAF9',
  400: '#78E1F6',
  500: '#10C3E7',
  600: '#0FB7D9',
  700: '#0C92AD',
  800: '#096E82',
  900: '#075868',
  950: '#053B45',
}

export const momentsPrimary = {
  25: '#FCFAFF',
  50: '#F9F5FF',
  100: '#F4EBFF',
  200: '#E9D7FE',
  300: '#D6BBFB',
  400: '#B692F6',
  500: '#9E77ED',
  600: '#7F56D9',
  700: '#6941C6',
  800: '#53389E',
  900: '#42307D',
  950: '#38276F',
}

export const purple = {
  25: '#F3F4F7',
  50: '#E6E8EF',
  100: '#9DA4BE',
  200: '#90caf9',
  300: '#6C779D',
  400: '#3B497D',
  500: '#0A1C5C',
  600: '#08164A',
  700: '#061137',
  800: '#040B25',
  900: '#020512',
  1000: '#010309',
}

export const yellow = {
  25: '#FEFDF0',
  50: '#FEFBE8',
  100: '#FEF7C3',
  200: '#FEEE95',
  300: '#FDE272',
  400: '#FAC515',
  500: '#EAAA08',
  600: '#CA8504',
  700: '#A15C07',
  800: '#854A0E',
  900: '#713B12',
  1000: '#542C0D',
}

export const grey = {
  25: '#FCFCFD',
  50: '#F8FAFC',
  100: '#EEF2F6',
  200: '#E3E8EF',
  300: '#CDD5DF',
  400: '#9AA4B2',
  500: '#697586',
  700: '#364152',
  900: '#121926',
  950: '#0D121C99',
}

export const greyDisabled = {
  100: '#F2F4F7',
}

export const green = {
  25: '#F6FEF9',
  300: '#75E0A7',
}

export const red = {
  25: '#FFFBFA',
  300: '#FDA29B',
  600: '#D92D20',
}

export const warningOrange = {
  300: '#FEC84B',
  600: '#DC6803',
}
