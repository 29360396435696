import { AbilityContext } from 'common/casl/components/theme-can'
import { buildThemeAbilityFor, TAppTheme } from 'common/casl/config/theme-ability'
import { ReactNode } from 'react'

interface IThemeAbilityContext {
  children: ReactNode
}

export const APP_THEME = (process.env.NEXT_PUBLIC_APP_THEME as TAppTheme) ?? 'moments'

export const CaslThemeAbilityContext = (props: IThemeAbilityContext) => {
  const ability = buildThemeAbilityFor(APP_THEME)

  return <AbilityContext.Provider value={ability}>{props.children}</AbilityContext.Provider>
}
