import { TAppTheme } from 'common/casl/config/theme-ability'
import { momentsTheme } from './moments-theme'
import { infocareTheme } from './infocare-theme'
import { APP_THEME } from 'common/context/casl-theme-ability-context'
import { createTheme, Theme } from '@mui/material/styles'

const THEME: Record<TAppTheme, unknown> = {
  moments: momentsTheme,
  infocare: infocareTheme,
}

export const theme = createTheme({ ...(THEME[APP_THEME] as Theme) })
