import { createTheme } from '@mui/material/styles'
import { dmSans } from './fonts'
import { green, grey, infocarePrimary, purple, red, warningOrange } from './colors'

const FONT_FAMILY = dmSans.style.fontFamily
const COLOR_PRIMARY = infocarePrimary[700]
const COLOR_PRIMARY_LIGHT = infocarePrimary[400]
const COLOR_SECONDARY = purple[400]
const COLOR_BACKGROUND = '#F5F5F5'
const COLOR_SUCCESS = green[300]

const COLOR_WARNING = warningOrange[600]
const COLOR_INFO = '#000000'

const COLOR_ERROR = red[600]
const COLOR_ERROR_LIGHT = red[300]

export const infocareTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: COLOR_PRIMARY,
      light: COLOR_PRIMARY_LIGHT,
    },
    secondary: {
      main: COLOR_SECONDARY,
    },
    background: {
      default: COLOR_BACKGROUND,
    },
    success: {
      main: COLOR_SUCCESS,
    },
    error: {
      main: COLOR_ERROR,
      light: COLOR_ERROR_LIGHT,
    },
    warning: {
      main: COLOR_WARNING,
    },
    info: {
      main: COLOR_INFO,
    },
  },
  typography: {
    fontFamily: FONT_FAMILY,
    h1: {
      fontSize: `2.2rem`,
      fontWeight: 300,
      color: grey[900],
      fontFamily: FONT_FAMILY,
    },
    h2: {
      fontSize: `1.8rem`,
      fontFamily: FONT_FAMILY,
      color: grey[900],
    },
    h3: {
      fontSize: `1.6rem`,
      fontFamily: FONT_FAMILY,
      color: grey[900],
    },
    h4: {
      fontSize: `1.4rem`,
      fontWeight: 600,
      color: grey[900],
    },
    h5: {
      fontSize: `1.3rem`,
      fontWeight: 600,
      color: grey[900],
    },
    h6: {
      fontFamily: FONT_FAMILY,
      fontWeight: 500,
      color: grey[900],
    },
    body1: {
      fontFamily: FONT_FAMILY,
      color: grey[500],
    },
    caption: {
      fontFamily: FONT_FAMILY,
      fontSize: `0.8rem`,
      color: grey[500],
    },
    overline: {
      fontSize: `0.8rem`,
      fontFamily: FONT_FAMILY,
      color: grey[500],
    },
    body2: {
      fontFamily: FONT_FAMILY,
      color: grey[500],
    },
    subtitle1: {
      fontSize: `1rem`,
      fontFamily: FONT_FAMILY,
      color: grey[400],
    },
    button: {
      fontFamily: FONT_FAMILY,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        container: {
          backdropFilter: 'blur(5px)',
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR_BACKGROUND,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR_PRIMARY_LIGHT,
        },
      },
    },
  },
})
